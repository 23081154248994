/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Treo's base styles.
/* ----------------------------------------------------------------------------------------------------- */
// @supports(padding:max(0px)) {
//   body, header, footer {
//       padding-left: min(0vmin, env(safe-area-inset-left)) !important;
//       padding-right: min(0vmin, env(safe-area-inset-right)) !important;
//   }
// }

// html {
//   min-height: calc(100% + env(safe-area-inset-top)) !important;
//   padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left) !important;
// }

@supports (padding-top: constant(safe-area-inset-top)) {
  body {
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  }
}

@media (display-mode: fullscreen) {
  body {
    padding: 0;
  }
}

@media (display-mode: standalone) {
  body {
    padding: 0;
  }
}

/* Older browsers (which don't support CSS variables) */
body {
  height: 100%;
}


@supports (padding-top: env(safe-area-inset-top)) {
  body {
    --safe-area-inset-top: env(safe-area-inset-top);
    height: calc(100% + var(--safe-area-inset-top));
  }
}

@media (display-mode: fullscreen) {
  body {
    height: 100%;
  }
}

@media (display-mode: standalone) {
  body {
    height: 100%;
  }
}


// $material-primary:rgb(33, 150, 243);
$material-light:#E3F2FD;
$background-tran: transparent;
$material-primary: #1c64f2;
$material-light: #f4511e;
$material-error: #f4511e;

$light-blue:  #1fc3f3;

$fontfamily: Roboto,
"Segoe UI",
"Helvetica Neue",
sans-serif;


//New
$r6-background-gray:#f1f3f4;
$icon-small-color:#333;
$lighter-blue:#5471d2;
$header-footer-bk:#b3b3b30f; //#d4e3f329;
$b-light: rgb(104, 117, 245);
$b-dark: #5850ec;
$info-text-color: #3367d6;


// * {
//   border-color: #efefef;
//   // border:0px;
// }

body {
  touch-action: pan-x pan-y !important;
}

ngx-mat-intl-tel-input {
  input:not(.country-search) 
  {
    width:auto !important;
  }
}



//Used to center graph spinners or loaders for specific components.
.center-spinner, .center-obj {
  position:absolute !important;
  top:50%;
  left:50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 99;
}



.mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:first-child)::before, [dir=rtl] .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:last-child)::before { 
  border-top-width: 4px;
  border-top-color: blue;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:last-child)::after, [dir=rtl] .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:first-child)::after {
  border-top-width: 4px;
  border-top-color: blue;
}

.mat-stepper-horizontal-line {
  border-top-width: 4px;
  border-top-color: blue;
}






.warning-icon {
  color: #ef3a3a !important;
}

.icon-path {
  svg {
    path:first-of-type {
      color: #03f303;
    }
  }
}

.mat-valid {
  color:#4CAF50 !important;
}

.mobile{
  .mat-tab-link {
    max-width:80px !important;
    min-width:80px !important;
  }
}


[mat-raised-button] {
  width: auto;
}

.mat-checkbox-frame {
  border-radius: 3px !important;
}

mat-grid-list {
  max-width: 1403px;
  margin: 16px;
}

mat-sidenav-layout {
  height: 100vh;
}

mat-sidenav {
  width: 320px;
}

.icon-20 {
  font-size: 20px;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.desc {
  .mat-form-field-label-wrapper {
    margin-left: 10px !important;
    margin-top: 10px !important;
  }

  .mat-form-field-infix {
    padding-bottom: 0px !important;
  }
}

.gfont-button {
  .mat-button-wrapper {
    // font-family: "Comfortaa", Roboto !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    letter-spacing: 0.25px !important;
    text-transform: none !important;
    opacity: 1 !important;
    //color: #3c4043 !important;
    color: black !important
  }
}

// //disables focus
// .mat-button:focus .mat-button-focus-overlay {
//   background-color: transparent;
// }

// .mat-button-focus-overlay {
//   background-color: transparent !important;
// }

:focus {
  outline: none;
}

.popoup-dialog-container {
  .mat-dialog-container {
    padding: 0 !important;
    border-radius: 8px !important;

    mat-dialog-content {
      padding: 0;
      margin: 0;
    }
  }
}

.popoup-dialog-container-menu {
  .mat-toolbar {
    position: fixed;
    top: 0;
    z-index: 9999;
  }

  .mat-dialog-container {
    padding: 0 !important;
    border-radius: 8px !important;

    mat-dialog-content {
      padding: 0;
      margin: 0;
    }
  }
}

.popoup-dialog-container-search {
  .mat-toolbar {
    position: fixed;
    top: 0;
    z-index: 9999;
  }

  .mat-dialog-container {
    width: 0;
    height: 0;
    padding: 0 !important;
    border-radius: 8px !important;

    mat-dialog-content {
      padding: 0;
      margin: 0;
    }
  }
}

.add-new-invoice-subitem {
  .mat-dialog-container{
    padding-top:0px;
    display: flex;
    padding: 0px;
    overflow: hidden;
  }
}





// [mat-button] {
//   text-transform: uppercase !important;
//   font-weight: 500 !important;
// }

.treo-theme-light .mat-simple-snackbar-action {
  color: $light-blue !important;
}

.med-dialog-client {
  .mat-dialog-container {
    padding: 0 !important;
    border-radius: 0px !important;

    mat-dialog-content {
      padding: 0;
      margin: 0;
    }
  }

  .med-dialog {

    mat-toolbar-layout,
    mat-toolbar-row,
    .mat-toolbar-layout {
      height: 40px !important;
    }

    mat-tabs-canvas,
    mat-tab-canvas,
    mat-tab-item,
    .mat-tab-label {
      height: 40px !important;
      line-height: 40px !important;
      min-width: 50px !important;
    }

    mat-tabs-wrapper {
      height: 40px !important;
    }
  }
}

.rm {
  .mat-toolbar {
    background: white;
    color: gray;
  }

  .mat-toolbar-multiple-rows {
    min-height: 30px;
    height: 45px;
  }
}

.mat-toolbar.mat-primary {
  background: white;
  color: rgb(0, 0, 0);
  border-bottom: 1px solid #e0e0e0;
  box-shadow: none;

  .mat-toolbar-row {
    opacity: 0.68;
  }
}

.app-edit-card {

  margin-bottom: 65px !important;

  mat-form-field {
    width: 100%;
  }

  .mat-card-title {
    color: $lighter-blue;
  }



  .header-main {

    .mat-card-header-text {
      // max-width: 160px;
      overflow: hidden;
      text-overflow: ellipsis;
      flex-shrink: 10000;
    }

    //background: white;
    background:$header-footer-bk;
    line-height: 65px;

    .mat-card-title {
      font-family: "Metropolis", Roboto;
      font-weight: 500;
    }

    .mat-card-subtitle {
      font-family: Roboto;
      font-weight: 400;
    }

    .action-buttons {
      .mat-icon {
        color: $icon-small-color;
        width: 20px;
      }
    }


  }

  .padtop0 {
    padding-top: 0px !important;
  }

  .section-header {
    //background:$lighter-blue;
    border-top: 2px solid $lighter-blue;

    mat-card-subtitle {
      //color:white;
      color: #5471d2;
      font-weight: 500;
      text-transform: uppercase;
    }

  }

  &.section-header:first {
    border-top: none;
  }

  .mat-card-actions {
    //border-top: 2px solid #efefef;
    background: $header-footer-bk; //remove if old style
  }

}

.app-search-input {
  padding: 15px;
  padding-top: 0;
  padding-bottom: 0;

  mat-form-field {
    width: 100%;
  }

  .mat-form-field-prefix {
    align-self: flex-end;
    margin-bottom: 3px;
    color: #333;
  }
}


.calendar5-list, .contacts-list {
  table {
      background: transparent;

      th {
          background: treo-color("cool-gray", 50);
      }

      tr.contact-row {

          &:hover,
          &.selected {
             
            background: #EBF5FF;
              
          }

          td {
              &.mat-column-name {
                  .avatar {
                      >span {
                          background: treo-color("cool-gray", 200);
                          color: treo-color("cool-gray", 600);
                      }
                  }
              }
          }
      }
  }
}


.mfo {
  .form-control {
    // border: 2px solid #f5f5f5;
    background: rgba(0, 0, 0, 0.04);
    border-radius: none;
    box-sizing: border-box;
    height: 35px;
    width: 100%;
    font-size: 14px;
    // border: 2px solid #efefef;
    border-radius: 3px;
    line-height: 14px;
    padding: 5px 10px 5px 10px;

    &.ng-dirty.ng-invalid.ng-touched {
      border-color: red;
    }
  }

  .form-control:focus {
    // border: 2px solid #a1cdf1;
  }

  .error-block {
    margin-bottom: 0;
    padding: 7px;
  }

  .form-group {
    display: flex;
  }

}

// @include scrollbars(0.5em, gainsboro);

//loading bar stuff

#loading-bar,
#loading-bar-spinner {
  pointer-events: none;
  -webkit-pointer-events: none;
  -webkit-transition: 350ms linear all;
  -moz-transition: 350ms linear all;
  -o-transition: 350ms linear all;
  transition: 350ms linear all;
}

#loading-bar.ng-enter,
#loading-bar.ng-leave.ng-leave-active,
#loading-bar-spinner.ng-enter,
#loading-bar-spinner.ng-leave.ng-leave-active {
  opacity: 0;
}

#loading-bar.ng-enter.ng-enter-active,
#loading-bar.ng-leave,
#loading-bar-spinner.ng-enter.ng-enter-active,
#loading-bar-spinner.ng-leave {
  opacity: 1;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

app-calendar-selector {
  .mat-checkbox-checked.c-red .mat-checkbox-background {
    background-color: #f4511e !important;
  }

  .mat-checkbox.red .mat-checkbox-frame {
    border-color: #f4511e !important;
  }

  .mat-checkbox-checked.c-blue .mat-checkbox-background {
    background-color: #039be5 !important;
  }

  .mat-checkbox.c-blue .mat-checkbox-frame {
    border-color: #039be5 !important;
  }

  .mat-checkbox-checked.c-yellow .mat-checkbox-background {
    background-color: #f6bf26 !important;
  }

  .mat-checkbox.c-yellow .mat-checkbox-frame {
    border-color: #f6bf26 !important;
  }

  .mat-checkbox-checked.c-green .mat-checkbox-background {
    background-color: #7cb342 !important;
  }

  .mat-checkbox.c-green .mat-checkbox-frame {
    border-color: #7cb342 !important;
  }

  .mat-checkbox-checked.c-purple .mat-checkbox-background {
    background-color: #8e24aa !important;
  }

  .mat-checkbox.c-purple .mat-checkbox-frame {
    border-color: #8e24aa !important;
  }

  .mat-checkbox-checked.c-lightpurple .mat-checkbox-background {
    background-color: #7986cb !important;
  }

  .mat-checkbox.c-lightpurple .mat-checkbox-frame {
    border-color: #7986cb !important;
  }

  .mat-checkbox-checked.c-lightpurple .mat-checkbox-background {
    background-color: #ff887c !important;
  }

  .mat-checkbox.c-lightpurple .mat-checkbox-frame {
    border-color: #ff887c !important;
  }
}


@media print {

  mat-toolbar {
    display: none !important;
  }

  .main-header {
    display: none !important;
  }

  .mat-drawer-content {
    margin-left: 0px !important;
  }

  .sidebar-panel {
    display: none !important;
  }

}

//NEW STUFF

.inner-content-invoice {
  .inner-h1 {
    font-size: 20px;
    letter-spacing: -1.5px;
  }
  
  .header {
    padding: 20px 32px !important;
  }
  
  .main-inner {
    padding:0px !important;
  }
}


// Need re-factoring.

.appointment-popup-form {
  .mat-dialog {
    transition: none !important;
  }

  .mat-dialog-container {
    padding: 0 !important;
    border-radius: 0px !important;

    mat-dialog-content {
      padding: 0;
      margin: 0;
    }

    .title-container {
      max-width: calc(560px + ((100% - 1049px) * 0.166667));
    }

    .title {
      &.mat-form-field {
        height: 100%;
        width: 100%;
      }

      .mat-form-field-infix {
        background-color: rgba(0, 0, 0, 0.04) !important;
        margin-top: 0px;
        border-top: 0;
        height: 45px;
        padding: 5px 3px 5px 10px;
        border-radius: 2px;
        // min-width: 500px;
        font-size: 20px;
      }

      .mat-form-field-label {
        padding: 3px 3px 5px 10px;
        color: gray;
      }
    }
  }

  @media screen and (max-width: 599px) {
    .mat-dialog-container {
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
    }
  }
}


app-calendar-popup, app-calendar-popup-new {
  & > div:first-child {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
      background: white;
      width:458px !important;
      
      border-radius:8px;
      
      mat-toolbar {
        border-top-left-radius:8px;
        border-top-right-radius:8px;
        background: white;
        color: gray;
      }
      .container {
        border-bottom-left-radius:8px;
        border-bottom-right-radius:8px;
      }   
        .mat-toolbar-multiple-rows {
          min-height: 30px;
          height: 45px;
        }

    .button-badge {
      .mat-badge-content .mat-badge-active {
        right:0 !important;
        top:0 !important;
        
        // right: -1px !important;
        // top: 16px !important;
      }
    }
  }
}


.hb-textarea-fix {
  .mat-form-field-label {
    padding:0.75rem !important;
  }
}

.med-select {
  height: 36px;

  &.g-style {
    background: white !important;
    border: 1px solid #dadce0;
    height: 40px !important;
    border-radius: 4px;
    overflow: hidden;

    .mat-form-field {
      width: 100%;
    }

    .mat-form-field-underline {
      display: none !important;
    }

    .mat-select-value-text {
      font-size: 14px !important;
      font-weight: 500 !important;
      letter-spacing: 0.25px !important;
      color: black;
    }

    .mat-select-arrow-wrapper {
      margin-top: 3px;
    }
  }

  .mat-form-field-flex {
    padding: 12.5px 10px 10.5px 10px;
    background: white !important;
    border-radius: 2px !important;
  }

  &.select-small {
    height: 30px;

    .mat-form-field-flex {
      padding: 12.5px 10px 6.5px 10px !important;
    }
  }

  .mat-form-field-infix {
    border-top: 0;
    margin: 0px;
    border: 0px;
    line-height: 15px;
    padding: 0;
  }

  .mat-form-field-appearance-legacy .mat-form-field-infix {
    padding: 0px;
  }

  &.outline-blue {
    .mat-form-field-flex {
      background: #efefef !important;
      border-radius: 999px !important;
      //border: 2px solid #03a5ff;
      //border: 2px solid #0046db;
      border: 1px solid #efefef;
      padding: 2.5px 5px 2.5px 4px !important;
    }

    .mat-select-value-text {
      font-weight: 500;
    }

    .mat-form-field-underline {
      display: none;
    }

    .mat-select-arrow-wrapper {
      margin-top: 15px;
    }
  }
}


.hb-form-inputs {

  .mat-form-field-infix {
    border-top: 0px;
    width: 100% !important;

  }

  .mat-form-field-appearance-fill .mat-form-field-flex {
    
    padding-top: 0.35em;
    padding-bottom: 0px;
    background-color: transparent !important;

    &:hover {
      background-color: rgba(0, 0, 0, 0.04) !important;
      transition: background 0.5s ease;
    }
  }





&.hb-form-transparent {

  &:hover {

    .mat-form-field-appearance-fill .mat-form-field-flex 
    {
      background-color:transparent !important;
    }
  }
}

&.hb-form-unpadded{
  .mat-form-field-flex {
    padding:0px !important;
  }
}

&.hb-paginator {
  .mat-form-field-wrapper{

        padding-bottom:0px !important;
    
      .mat-form-field-underline  {
        bottom:0px !important;
      }

    }
    
    .mat-paginator-navigation-previous, .mat-paginator-navigation-next {
      align-items: center !important;
      justify-content: center !important;
      width: 30px !important;
      height: 30px !important;
  }
  
  
}


&.hb-form-100 {
  .mat-form-field {
    width:100%;
  }
}

 & .hide-underline {
    .mat-form-field-underline{
      display:none;
    }
  }

  & .slim {

    .mat-form-field-subscript-wrapper {
      margin-top:0px !important;
    }

    // .mat-form-field-wrapper {
    //   margin-bottom: -1.25em !important;
    // }
  }

  & .no-wrap-pad {
      .mat-form-field-wrapper {
        margin-bottom: 0px !important;
    }
  }

  &.p-hover, &.hb-form-fill
  {
    .mat-form-field-appearance-fill .mat-form-field-flex {
      background-color: rgba(0, 0, 0, 0.04) !important;
      border-radius: 4px;
    }

    .mat-form-field-underline::before {
      background:none!important;
    }

  }

  .mat-icon {
    color: #5f6368;
    font-size: 22px;
    line-height: 35px;
    max-width: 22px;
    min-width: 22px;




  }

  .mat-form-field-suffix {
    position: initial;

    mat-datepicker-toggle {
      .mat-icon-button {
        width: 2.5em;
        height: 2.5em;
        min-height: 20px !important;
        position: absolute;
        top: 0px;
        right: 5px;
      }
    }


    .mat-icon {

      max-height: 20px !important;
      min-height: 20px !important;
      position: absolute;
      top: 6px;
      right: 5px;

    }
  }

}

.hb-form-fill
  {
    .mat-form-field-appearance-fill .mat-form-field-flex {
      background-color: rgba(0, 0, 0, 0.04) !important;
    }
  }


.hb-experimental {
.mat-form-field-wrapper {
  margin-bottom: 16px;
  padding-bottom: 0;

  .mat-form-field-flex {
      position: relative;
      display: flex;
      align-items: stretch;
      // min-height: 48px;
      height:35px;
      border-radius: 4px 4px 0 0;
      padding: 0 8px;

      background-color: white;
    

     .mat-form-field-prefix {

          > .mat-icon {
              margin-right: 0px;
          }

          > .mat-icon-button {
              margin: 0 4px 0 -8px;
          }

          > .mat-select {
              margin-right: 10px;
          }

          > .mat-datepicker-toggle {
              margin-left: -8px;
          }

          > *:not(.mat-icon):not(.mat-icon-button):not(.mat-select):not(.mat-datepicker-toggle) {
              margin-right: 0px;
          }
      }

      .mat-form-field-suffix {

          > .mat-icon {
              margin-left: 12px;
          }

          > .mat-icon-button {
              margin: 0 -8px 0 4px;
          }

          > .mat-select {
              margin-left: 10px;
          }

          > .mat-datepicker-toggle {
              margin-right: -8px;
          }
      }

      .mat-form-field-prefix,
      .mat-form-field-suffix {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          @apply text-hint #{'!important'};

          .mat-icon-button {
              width: 40px;
              min-width: 40px;
              height: 40px;
              min-height: 40px;
          }

          .mat-icon,
          .mat-icon-button:not(.mat-button-disabled),
          .mat-select-value {
              @apply text-hint;
          }

          /* Remove the margins from the mat-icon if it's inside a button */
          /* Force the icon size to 24 */
          .mat-button,
          .mat-raised-button,
          .mat-icon-button,
          .mat-stroked-button,
          .mat-flat-button,
          .mat-fab,
          .mat-mini-fab {

              .mat-icon {
                  margin: 0 !important;
                  @apply icon-size-6;
              }
          }

          /* Datepicker default icon size */
          .mat-datepicker-toggle-default-icon {
              @apply icon-size-6;
          }

          /* Make mat-select usable as */
          /* prefix and suffix */
          .mat-select {
              display: flex;
              align-items: center;

              .mat-select-trigger {
                  display: flex;
                  align-items: center;

                  .mat-select-value {
                      display: flex;
                      max-width: none;

                      mat-select-trigger {

                          .mat-icon {
                              margin: 0 !important;
                          }
                      }
                  }

                  .mat-select-arrow-wrapper {
                      display: flex;
                      align-items: center;
                      transform: none;
                      margin-left: 4px;

                      .mat-select-arrow {
                          min-height: 0;
                      }
                  }
              }
          }
      }

      .mat-form-field-infix {
          position: static;
          display: flex;
          align-items: center;
          width: 88px;
          padding: 0;
          border: 0;

          .mat-input-element {
              padding: 14px 0;
              margin-top: 0;
          }

          /* Textarea */
          textarea.mat-input-element {
              display: flex;
              align-self: stretch;
              min-height: 36px;
              height: auto;
              margin: 10px 0;
              padding: 4px 6px 4px 0 !important;
              transform: none;
          }

          /* Select */
          .mat-select {
              display: inline-flex;

              .mat-select-trigger {
                  display: inline-flex;
                  align-items: center;
                  width: 100%;

                  .mat-select-value {
                      display: flex;
                      position: relative;
                      max-width: none;

                      .mat-select-value-text {
                          display: inline-flex;

                          > * {
                              overflow: hidden;
                              white-space: nowrap;
                              text-overflow: ellipsis;
                          }
                      }
                  }
              }

              .mat-select-arrow-wrapper {
                  transform: translateY(0);

                  .mat-select-arrow {
                      margin: 0 0 0 8px;
                  }
              }
          }

          /* Chips */
          .mat-chip-list {
              width: 100%;
              margin: 0 -8px;

              .mat-chip-input {
                  margin: 0 0 0 8px;
              }
          }

          .mat-form-field-label-wrapper {
              top: -25px;
              height: auto;
              padding-top: 0;
              overflow: visible;
              pointer-events: auto;

              .mat-form-field-label {
                  position: relative;
                  top: 0;
                  margin-top: 0;
                  backface-visibility: hidden;
                  transition: none;
                  font-weight: 500;
                  @apply text-default #{'!important'};
              }
          }
      }
  }

  /* Remove the underline */
  .mat-form-field-underline {
      // display: none;
      bottom: 0px;
  }

  /* Subscript tweaks */
  .mat-form-field-subscript-wrapper {
      position: relative;
      top: auto;
      padding: 0;
      margin-top: 0;
      font-size: 12px;
      font-weight: 500;
      line-height: 1;

      > div {
          display: contents; /* Remove the div from flow to stop the subscript animation */
      }

      .mat-error,
      .mat-hint {
          display: block;
          margin-top: 4px;
      }

      .mat-hint {
          @apply text-hint #{'!important'};
      }
  }
}
}


//End of validated as required



.treo-theme-light .mat-input-element:disabled {

  &.not-disable {
    color: black !important
  }

}

.hb-select {
  height: 36px;
  min-width: 170px;
  &.select-small {
    height: 30px;

    .mat-form-field-flex {
      padding: 6.5px 10px 6.5px 10px !important;
    }
  }
  .mat-form-field-infix {
    min-width: 180px;
    width: 180px !important;

  }
  .mat-form-field-appearance-legacy .mat-form-field-infix {
    padding: 0px;
  }

  .mat-select-arrow {
    margin: 5px 4px -4px 2px !important;
  }

}



.mat-option-text {
  font-family: Roboto;
  font-size: 14px !important;
  -webkit-font-smoothing: antialiased;
  // -webkit-font
}


.monthViewHeight {
  

    height:100%;
    
    .fc
    {
      height:100%;
      
      .fc-monthHB-view {
        height:100%;
    
      .fc-scrollgrid {
        height:100%;
    
        .fc-scrollgrid-section {
          height:100%;
    
        .fc-scroller-harness {
          height:100%;
    
          .fc-scroller {
            height:100%;
            
            .fc-daygrid-body {
              height:100%;
              .fc-scrollgrid-sync-table {
                height:100%;
              }

            }
    
          }
    
        }
    
    
        }
      }
    }

}
  
}






.fc {

  background: white;
}

.fc th {
  vertical-align: middle !important;
  padding: 0;
}

.fc .fc-scrollgrid table {
  border-bottom-style: hidden;
}

.fc-theme-standard td, .fc-theme-standard th {
  border-right: 0 !important;
}


.fc {




  .fc-timegrid-slot-label {
    border: 0px;
  }
  
  .fc-timegrid-slot-label {
    vertical-align: top !important;
  }
  
  td[data-time="00:00:00"]>.fc-timegrid-slot-label-frame {
    margin-top: 0px !important;
  }
  
  
  .fc-day-today {
    background: transparent !important;
  }
  
  .fc-timegrid-slot-label-cushion {
    font-size: 0.7rem !important;
    text-transform: uppercase;
  }
  
  .fc-daygrid-block-event {
    border: 0px !important;
  
  }
  
  
  .fc-divider {
  
    padding: 0 !important;
    border-top: 1px solid #e0e0e0 !important;
    border-bottom: 0px !important;
    display: none !important;
  }
  
  .fc-highlight {
    background: transparent !important;
  }
  
  .fc-scroller-harness {
    td {
      // min-height: 52px;
      // height: 52px;
    }
  }
  
  .fc-timegrid-slot-label-frame {
    margin-top: -10px !important;
  }
  
  .fc-scrollgrid {
    border: none !important;
  }
  
  .fc-hb-lifted {
    z-index: 9999 !important;
    opacity: 1 !important;
    filter: brightness(115%);
    //border:1px solid white !important;
    -o-transition: .3s;
    -ms-transition: .3s;
    -moz-transition: .3s;
    -webkit-transition: .3s;
    transition: .3s;
  
    border: 1px solid white !important;
    border-radius: 3px;
    box-sizing: border-box;
  }
  
  .fc-theme-standard .fc-list{
    border:none !important;
    background: white;
    }
    .fc-list-empty-cushion{
        font-size: 16px;
        font-weight: 500;
    }
  
    .fc-theme-standard .fc-list {
   
      border-left: 0px;
      border-right: 0px;
  }
  
  .fc-list-event{
    cursor: pointer;
  }
  
  
  .fc-selected-event {
    // -webkit-box-shadow: 0 10px 6px -6px #777;
    // -moz-box-shadow: 0 10px 6px -6px #777;
    //      box-shadow: 0 10px 6px -6px #777;
  
    // box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
    transition: opacity 100ms linear;
    opacity: 1;
    z-index: 999999;
    box-shadow: 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%), 0px 3px 5px -1px rgb(0 0 0 / 20%);
  }
  
  
  
  .fc-event-main {
    padding-left: 5px !important;
    padding-top:3px !important;
    padding-right: 3px !important;
    border-radius: 3px;
    cursor: pointer;
    font-family:Roboto,Helvetica,Arial,sans-serif;
    font-size:12px;

    .event-title {
      text-rendering: geometricPrecision;
      user-select: none;
      font-size: 12px;
      letter-spacing: .1px;
      line-height: 15px;
      -webkit-font-smoothing: antialiased;
      white-space: normal;
      word-wrap: break-word;
      font-weight: 500;
      word-break: break-word;
    }

  }
  
.fc-col-header-cell {
  border: none !important;
}

.fc-col-header {
	.fc-timegrid-axis {
	border:none !important;	
}

&.fc-resource {
  padding-top:10px;
}


.fc-resource, .last-day-view{
  border-right:1px solid #ddd !important;
}

}




// .fc-resource{
//   border-right:2px solid red;
// }




  .hsmall {

    font-size: 14px;
    text-align: left;
    font-weight: 500;
    text-align: center;

    &.today {
      color: $material-primary
    }

    &.before {
      color: #9e9e9e;
    }

    &.after {
      color: #525252;
    }

    &.list {
      display:inline-block;
    }

  }

  .fc-event-past {
    opacity: 0.4 !important;
  }

  .fc-event-mirror {
    transition: ease all 0.3s !important;
    // background:gray !important;
    // -webkit-box-shadow: 0 10px 6px -6px #777 !important;
    // -moz-box-shadow: 0 10px 6px -6px #777 !important;
    // box-shadow: 0 10px 6px -6px #777 !important;
   
    z-index: 999999px !important;
    border: 0px !important;
    //background-color: $material-primary !important; // #0080ffed!important;
    background-color: rgb(121, 134, 203);
    border-color: rgb(121, 134, 203);
    margin-top: 1px !important;
  

    .fc-event-main {
      box-shadow: 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%), 0px 3px 5px -1px rgb(0 0 0 / 20%) !important;
    }

    .fc-event-main::before {
      box-shadow: 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%), 0px 3px 5px -1px rgb(0 0 0 / 20%) !important;
    }

    &.fc-event-past {
      opacity: 0.4 !important;
    }
    &.small {
      opacity: 1 !important;
    }
  }


  &.small {
    .fc-timegrid-slot {
      // min-height: 1.5rem !important;
      // height: 1.5rem !important;
    }
    .fc-timegrid-slot-label-cushion {
      font-size: 0.65rem !important;
  }
    .hsmall {
      font-size: 12px !important;
      text-transform: uppercase !important;
  }

  .fc-event-past {
      opacity: 1 !important;
  }



  }


  &.businessHours {
    .fc-timegrid-slot {
      // min-height: 2.0rem !important;
      // height: 2.0rem !important;
    }
  }

  .fc-list-day-cushion{
    text-align: left;

    .text-color {
      color: $material-primary;
      text-transform: uppercase;

      &.before {
        color: #9e9e9e;
      }
  
      &.after {
        color: #525252;
      }
    }

  }
  
  .icon-list-gray{
    color:#525252;
  }


  .hlarge {

    height: 45px;
    line-height: 45px;
    text-align: left;
    font-size: 48px;
    font-weight: 400;

    &.smaller {
      font-size: 16px;
      font-weight: 500;
      line-height: 26px;
      height: 26px;
      padding-bottom: 10px;
      text-align: center;
    }

    &.today {
      // color:#4285f4!important;

      background: $material-primary !important;
      width: 26px;
      height: 26px;
      line-height: 26px;
      border-radius: 50%;
      text-align: center;
      color: white;
    }

    &.before {
      color: #9e9e9e;
    }

    &.after {
      color: #212121;
    }
  }
}

.size-30 {
  .fc-timegrid-slot {
    min-height: 2.5rem !important;
    height: 2.5rem !important;
  }
}

.size-15 {

    .fc-timegrid-slot-minor {
      border-top-style: none !important;
  }

  .fc-timegrid-slot {
    min-height: 15px !important;
    height: 15px !important;
  }

}



app-calendar-datepicker {
  .mat-calendar-body-cell {
    font-size: 11px;
  }
}


$border-color:rgb(224, 224, 224);










.time-panel
{
    background:white;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    max-width: 188px;
    width: 188px;
    display: block;
    max-height: 200px;
    overflow-y: auto
}


::-webkit-scrollbar {
  background: transparent;
  height: 8px;
  width: 8px;
}
::-webkit-scrollbar-thumb {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #dadce0;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  min-height: 40px;
}



telephone-input{
.material {
    &.mobile
    {
      .mat-form-field{
        width: 100%;
      }

      .mat-form-field-flex{
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
    }
    
    }
  }
}


@media print {

  html, body {
    width: 210mm;
    height: 297mm;
  }

  // .flex {
  //   display:block !important;
  // }

  // .inline-block {
  //   display:block !important;
  // }

  // @page {
  //   size: A4;
  //   margin: 0;
  // }


   .line-item-grid {
      max-width: 680px !important;
   }

  .mat-drawer {
    display:none! important;
  }

  .treo-vertical-navigation, treo-vertical-navigation-appearance-default{
    display:none;
  }

  .header {
    display:none !important;
  }
 
  @page { margin: 0; }


.mat-horizontal-stepper-header-container{
  display: none !important;
  padding:0px;
}

  // #container{
  //   box-shadow: none !important;
  //   overflow: visible i !important;
  //   padding:0 !important;
  //   margin:0 !important;
  // }

  // .line-items{
  //   page-break-inside:auto; 
  // }
  // .content-layout.fullwidth-standard-inner-scroll
  // {
  //   overflow: visible;
  //   position: static;
  // }

  // .payment-info {
  //   page-break-after: auto;
  // }

  body {
    position: unset !important;
  }


  
  .remove-button, button, .mat-button {
    display:none !important;
  }

  // app-invoice-add #container {
  //   padding:3px;
  // }


  
  ::-webkit-input-placeholder { /* WebKit browsers */
    color: transparent !important;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: transparent !important;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: transparent !important;
}
:-ms-input-placeholder { /* Internet Explorer 10+ */
    color: transparent !important;
}



}


.ngx-mat-tel-input-container {
  input {
    margin-left:80px !important;
  }
  .country-selector {
    top:2px !important;
  }

}